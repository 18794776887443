import { Constant } from '../Actions/Constant'
const INITIAL_STATE = {
    list: [],
    role_permission: []
}
export default (state = INITIAL_STATE, action) => {
    let data;
    switch (action.type) {
        case Constant.FETCHROLESLIST:
            return ({
                ...state,
                list: action.payload.data.data,
            })
        case Constant.FETCHROLESPERMISSION:
            return ({
                ...state,
                role_permission: action.payload.data,
            })
        case Constant.ADDROLES:
            data = state.list;
            return ({
              ...state,
              list: data,
          })
        case Constant.DELETEROLES:
            data = state.list;
            data = state.list.filter(a => a.id != action.payload)
            return ({
                ...state,
                list: data,
            })

        case Constant.UPDATEROLES:
            data = state.list;
            let index = data.findIndex(a => a.id == action.payload.data.id)
            data.splice(index, 1, action.payload.data)
            return ({
                ...state,
                list: data,
            })
        default:
            return state;
    }
}

import { Constant } from '../Actions/Constant'
const INITIAL_STATE = {

    data: [],
    groupLog: []

}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Constant.GET_LOGS:
            return ({
                ...state,
                data: action.payload.data,

            })
        case Constant.GET_GROUP_BY_CATEGORIES:
            return ({
                ...state,
                groupLog: action.payload.data,

            })
        case Constant.CLEAR_GROUP_BY_CATEGORIES:
            return ({
                ...state,
                groupLog: [],

            })
        case Constant.Logout:
            return INITIAL_STATE

        default:
            return state;

    }

}
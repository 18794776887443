import { Constant } from '../Actions/Constant'
const INITIAL_STATE = {
    data: [],
    sensorsDependencies: [],
    dashboardData: [],
    overView: '',
    summary: '',
    detail: '',
    paginatedData: [],
}
export default (state = INITIAL_STATE, action) => {
    let data;
    switch (action.type) {
        case Constant.FETCHSENSORSLIST:
            return ({
                ...state,
                data: !action.payload.data?.data ? action.payload.data : action.payload.data,
            })
        case Constant.FETCHDASHBOARDLIST:
            return ({
                ...state,
                dashboardData: action.payload.data,
            })
        case Constant.SENSORSDETAIL:
            return ({
                ...state,
                detail: action.payload.data,
            })
        case Constant.DASHBOARDSUMMARY:
            return ({
                ...state,
                summary: action.payload.data,
            })
        case Constant.SENSORSOVERVIEW:
            return ({
                ...state,
                overView: action.payload.data,
            })
        case Constant.FETCHSENSORSDEPENDENCIES:
            return ({
                ...state,
                sensorsDependencies: action.payload.data,
            })

        case Constant.ADDSENSORS:
            data = state.data;
            data.data.unshift(action.payload)//Changes made by AM
            return ({
                ...state,
                data: data,
            })
        case Constant.DELETSENSORS:
            data = state.data.data;
            let temp = {};
            temp.data = data.filter(a => a.id != action.payload)
            return ({
                ...state,
                data: temp,
            })
        case Constant.UPDATESENSORS:
            data = state.data.data;
            let index = data.findIndex(a => a.id == action.payload.data.id)
            data.splice(index, 1, action.payload.data)
            temp = state.data;
            temp.data = data;
            return ({
                ...state,
                data: temp,
            })


        default:
            return state;

    }

}

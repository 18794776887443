export const Constant = {
    Login: "LOGIN",
    Logout: "LOGOUT",
    DEPENDENCIES: "DEPENDENCIES",
    USERSLIST: "USERSLIST",
    ADDUSER: "ADDUSER",
    DELETEUSER: "DELETEUSER",
    UPDATE_USER: "UPDATE_USER",
    LOADER_START:"LOADER_START",
    LOADER_END:"LOADER_END",
    CHANGE_PASSWORD:"CHANGE_PASSWORD",
    GET_USER: "GET_USER",
    UPDATE_PROFILE: "UPDATE_PROFILE",

    FETCHINDUSTRYLIST: "FETCHINDUSTRYLIST",
    ADDINDUSTRY: "ADDINDUSTRY",
    DELETEINDUSTRY: "DELETEINDUSTRY",
    UPDATEINDUSTRY: "UPDATEINDUSTRY",

    FETCHDEPARTMENTLIST: "FETCHDEPARTMENTLIST",
    FETCHALLDEPARTMENTLIST: "FETCHALLDEPARTMENTLIST",
    FETCHALLPARENTDEPARTMENTLIST:"FETCHALLPARENTDEPARTMENTLIST",
    ADDDEPARTMENT: "ADDDEPARTMENT",
    DELETEDEPARTMENT: "DELETEDEPARTMENT",
    UPDATEDEPARTMENT: "UPDATEDEPARTMENT",


    FETCHCATEGORYLIST: "FETCHCATEGORYLIST",
    ADDCATEGORY: "ADDCATEGORY",
    DELETECATEGORY: "DELETECATEGORY",
    UPDATECATEGORY: "UPDATECATEGORY",

    FETCHDEVICELIST: "FETCHDEVICELIST",
    ADDDEVICE: "ADDDEVICE",
    DELETEDEVICE: "DELETEDEVICE",
    UPDATEDEVICE: "UPDATEDEVICE",
    FETCHDEVICEDEPENDENCIES: "FETCHDEVICEDEPENDENCIES",


    FETCHSENSORSLIST: "FETCHSENSORSLIST",
    ADDSENSORS: "ADDSENSORS",
    DELETSENSORS: "DELETSENSORS",
    UPDATESENSORS: "UPDATESENSORS",
    FETCHSENSORSDEPENDENCIES: "FETCHSENSORSDEPENDENCIES",

    GET_LOGS: "GET_LOGS",
    GET_GROUP_BY_CATEGORIES: "GET_GROUP_BY_CATEGORIES",

    GET_ALL_REPORTS:"GET_ALL_REPORTS",
    FETCH_REPORTS_DEPENDENCIES:"FETCH_REPORTS_DEPENDENCIES",
    FETCH_MONTHLY_REPORTS_DEPENDENCIES:"FETCH_MONTHLY_REPORTS_DEPENDENCIES",


    FETCHGROUPLIST: "FETCHGROUPLIST",
    ADDGROUP: "ADDGROUP",
    DELETEGROUP: "DELETEGROUP",
    UPDATEGROUP: "UPDATEGROUP",

    FETCHPERMISSIONLIST: "FETCHPERMISSIONLIST",
    ADDPERMISSION: "ADDPERMISSION",
    DELETEPERMISSION: "DELETEPERMISSION",
    UPDATEPERMISSION: "UPDATEPERMISSION",

    FETCHROLESPERMISSION: "FETCHROLESPERMISSION",
    UPDATEROLEPERMISSION: "UPDATEROLEPERMISSION",

    FETCHROLESLIST: "FETCHROLESLIST",
    ADDROLES: "ADDROLES",
    DELETEROLES: "DELETEROLES",
    UPDATEROLES: "UPDATEROLES",


    FETCHMACHINELIST: "FETCHMACHINELIST",
    ADDMACHINE: "ADDMACHINE",
    DELETEMACHINE: "DELETEMACHINE",
    UPDATEMACHINE: "UPDATEMACHINE",

    FETCHSETTINGLIST: "FETCHSETTINGLIST",
    UPDATESETTING: "UPDATESETTING",


    FETCHDASHBOARDLIST: "FETCHDASHBOARDLIST",
    FETCHCATEGORYOVERVIEW: "FETCHCATEGORYOVERVIEW",
    FETCHSUBDAPRTMENTOVERVIEW: "FETCHSUBDAPRTMENTOVERVIEW",
    SENSORSOVERVIEW: "SENSORSOVERVIEW",
    DASHBOARDSUMMARY: "DASHBOARDSUMMARY",
    SENSORSDETAIL: "SENSORSDETAIL",
    FETCHMACHINEOVERVIEW: "FETCHMACHINEOVERVIEW",

    CLEAR_GROUP_BY_CATEGORIES:"CLEAR_GROUP_BY_CATEGORIES",
    FETCH_DEPARTMENT_REPORTS_DEPENDENCIES:"FETCH_DEPARTMENT_REPORTS_DEPENDENCIES",
    FETCH_TOTAL_OVERVIEW:"FETCH_TOTAL_OVERVIEW"


}

import { Constant } from '../Actions/Constant'
const INITIAL_STATE = {
    data: [],
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Constant.FETCHSETTINGLIST:
            return ({
                ...state,
                data: action.payload.data,
            })


        case Constant.UPDATESETTING:
            return ({
                ...state,
                data: action.payload.data,
            })
        default:
            return state;

    }

}
import { Constant } from '../Actions/Constant'
const INITIAL_STATE = {
    roles: [],
    industries: [],
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Constant.DEPENDENCIES:
            return ({
                ...state,
                roles: action.payload.data.roles,
                industries: action.payload.data.industries,
            })


        default:
            return state;

    }

}
import { Constant } from '../Actions/Constant'
const INITIAL_STATE = {
    data: [],
    reportsDependencies: [],
    departmentReports: []
}
export default (state = INITIAL_STATE, action) => {
    let data;
    switch (action.type) {
        case Constant.GET_ALL_REPORTS:
            return ({
                ...state,
                data: action.payload.data,
            })
        case Constant.FETCH_MONTHLY_REPORTS_DEPENDENCIES:
            return ({
                ...state,
                monthlyReports: action.payload.data,
            })
        case Constant.FETCH_DEPARTMENT_REPORTS_DEPENDENCIES:
            return ({
                ...state,
                departmentReports: action.payload.data,
            })
        case Constant.FETCH_REPORTS_DEPENDENCIES:
            return ({
                ...state,
                reportsDependencies: action.payload.data,
            })
        default:
            return state;

    }

}
import { Constant } from '../Actions/Constant'
const INITIAL_STATE = {
    list: [],
    overView:[]
}
export default (state = INITIAL_STATE, action) => {
    let data;
    switch (action.type) {
        case Constant.FETCHMACHINELIST:
            return ({
                ...state,
                list: action.payload.data.data,
            })
        case Constant.FETCHMACHINEOVERVIEW:
            return ({
                ...state,
                overView: action.payload.data.data,
            })

        case Constant.ADDMACHINE:
            data = state.list;
            data.unshift(action.payload)
            return ({
                ...state,
                industryList: data,
            })
        case Constant.DELETEMACHINE:
            data = state.list;
            let temp = data.filter(a => a.id != action.payload)
            return ({
                ...state,
                list: temp,
            })
        case Constant.UPDATEMACHINE:
            data = state.list;
            let index = data.findIndex(a => a.id == action.payload.id)
            data.splice(index, 1, action.payload)

            return ({
                ...state,
                list: data,
            })


        default:
            return state;

    }

}
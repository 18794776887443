import { Constant } from '../Actions/Constant'
const INITIAL_STATE = {
    authType: {},
    role: {},
    token: "",
    departments: [],
    detail: '',
    permissions: []
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Constant.Login:
            return ({
                ...state,
                token: action.payload.access_token,
                role: action.payload.data.roles[0],
                authType: action.payload.data,
                departments: action.payload.data.departments,
                detail: action.payload.data.detail,
                permissions: action.payload.data.permissions,
            })
        case Constant.GET_USER:
            return ({
                ...state,
                role: action.payload.data.roles[0],
                authType: action.payload.data,
                departments: action.payload.data.departments,
                detail: action.payload.data.detail,
                permissions: action.payload.data.permissions,
            })
        case Constant.UPDATE_PROFILE:
            return ({
                ...state,
                detail: action.payload.data.detail,
            })
        case Constant.Logout:
            return INITIAL_STATE

        default:
            return state;

    }

}

import { Constant } from '../Actions/Constant'
const INITIAL_STATE = {
    data: [],
    deviceDependencies: []
}
export default (state = INITIAL_STATE, action) => {
    let data;
    switch (action.type) {
        case Constant.FETCHDEVICELIST:
            return ({
                ...state,
                data: action.payload.data,
            })
        case Constant.FETCHDEVICEDEPENDENCIES:
            return ({
                ...state,
                deviceDependencies: action.payload.data,
            })

        case Constant.ADDDEVICE:
            data = state.data;
            data.unshift(action.payload)
            return ({
                ...state,
                data: data,
            })
        case Constant.DELETEDEVICE:
            data = state.data;
            let temp = data.filter(a => a.id != action.payload)
            return ({
                ...state,
                data: temp,
            })
        case Constant.UPDATEDEVICE:
            data = state.data;
            let index = data.findIndex(a => a.id == action.payload.data.id)
            data.splice(index, 1, action.payload.data)

            return ({
                ...state,
                data: data,
            })


        default:
            return state;

    }

}
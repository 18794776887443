import { Constant } from '../Actions/Constant'
const INITIAL_STATE = {
    userList: [],
}
export default (state = INITIAL_STATE, action) => {
    let data;
    switch (action.type) {
        case Constant.USERSLIST:
            return ({
                ...state,
                userList: action.payload.data,//.data,
            })

      case Constant.ADDUSER:
            data = state.userList;
            data.data.unshift(action.payload)
            return ({
                ...state,
                userList: data,
            })
        case Constant.DELETEUSER:
            data = state.userList.data;
            let temp = {};
            temp.data = data.filter(a => a.id != action.payload)
            return ({
                ...state,
                userList: temp,
            })
      case Constant.UPDATE_USER:
            data = state.userList.data;
            let index = data.findIndex(a => a.id == action.payload.data.id)
            data.splice(index, 1, action.payload.data)
            temp = state.userList;
            temp.data = data;
            return ({
                ...state,
                userList: temp,//data,
            })
        default:
            return state;

    }

}

import { Constant } from '../Actions/Constant'
const INITIAL_STATE = {
    data: [],
}
export default (state = INITIAL_STATE, action) => {
    let data;
    switch (action.type) {
        case Constant.FETCHPERMISSIONLIST:
            return ({
                ...state,
                data: action.payload.data,
            })


        case Constant.ADDPERMISSION:
            data = (state.data.data != undefined) ? state.data.data : state.data;
            data.unshift(action.payload)
            return ({
                ...state,
                data: data,
                //industryList: data,
            })
      case Constant.DELETEPERMISSION:
        data = (state.data.data != undefined) ? state.data.data : state.data;// = state.paginatedData.data;
        let temp = {};
        temp.data = data.filter(a => a.id != action.payload);
        return ({
          ...state,
          data: temp,
        })
      case Constant.UPDATEPERMISSION:
        data = (state.data.data != undefined) ? state.data.data : state.data;
        let index = data.findIndex(a => a.id == action.payload.data.id)
        data.splice(index, 1, action.payload.data)
        return ({
          ...state,
          data: data,
        })


      default:
        return state;

    }

}

import { Constant } from '../Actions/Constant'
const INITIAL_STATE = {
    data: [],
}
export default (state = INITIAL_STATE, action) => {
    let data;
    switch (action.type) {
        case Constant.FETCHGROUPLIST:
            return ({
                ...state,
                data: action.payload.data,
            })


        case Constant.ADDGROUP:
            data = state.data;
            data.unshift(action.payload)
            return ({
                ...state,
                data: data,
            })
        case Constant.DELETEGROUP:
            data = state.data;
            let temp = data.filter(a => a.id != action.payload)
            return ({
                ...state,
                data: temp,
            })
        case Constant.UPDATEGROUP:
            data = state.data;
            let index = data.findIndex(a => a.id == action.payload.data.id)
            data.splice(index, 1, action.payload.data)

            return ({
                ...state,
                data: data,
            })


        default:
            return state;

    }

}
